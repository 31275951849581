html, body, #root, .app, .pushable, .pusher, .appBody, .app {
  min-height: 100vh;
}
.app {
  /* background-color: #7ebad7; */
  font-family: 'Roboto Slab', serif;
  display: flex;
  flex-direction: column;
  align-items: center!important;
  padding: 40px 15px;
}
.version {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c2c2c2;
  font-weight: bold;
}
.blueFont {
  color: #515967;
}
.requiredField {
  font-size: 23px;
  color: #6E5068;
  font-weight: bold;
  margin-top: -3px;
  margin-left: 4px;
  line-height: 23px;
}
#ovmLogo {
  width: 150px;
  /* padding-bottom: 30px; */
  border-radius: 4px!important;
}
.application {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progressContainer, .instructions {
  width: 100%;
  max-width: 800px;
}
.progressContainer .bar {
  background-color: #197bc8!important;
}
.instructions {
  display: flex;
  flex-direction: column;
  color: #555;
}
.instructions span {
  color: #555;
}
.applicationHeaderSegment {
  background-color: #e0f2ff!important;
  display: flex;
  width: 80%;
  max-width: 450px;
  justify-content: space-between;
  align-items: center;
}
.applicationHeaderDetails {
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  padding-left: 10px;
  padding-right: 10px;
}
.applicationSegment {
  background-color: #e0f2ff!important;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.address {
  align-self: center;
  text-align: center;
  margin: 0px;
}
.buttonSection {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.nextButton, .backButton {
  width: 85px;
}
.backButton {
  margin-right: 12px;
}
.subSegment {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 2px solid rgba(0,0,0,0.15);
}
.lastSubSegment {
  border-bottom: 0px!important;
}
.addedContentDiv {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}
.addedContent {
  display: flex;
  align-items: center;
  border: 1px solid #e38532;
  color: #e38532;
  border-radius: 3px;
  padding: 3px 5px;
  margin-right: 5px;
}
.addedContent .k-icon {
  margin-left: 5px;
  margin-right: 2px;
  color: #e38532;
}
.k-form-field {
  margin-top: 5px!important;
}
.k-form-legend {
  font-size: 16px!important;
}
.customfield200 {
  width: 50%;
  max-width: 200px;
}
.customfield350 {
  max-width: 350px;
}
.customfield600 {
  max-width: 600px;
}
.checkboxMargin {
  margin-right: 10px;
}
.groupingDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.groupedField {
  /* width: 50%; */
  max-width: 350px;
}
.employmentDisclaimer {
  color: #e38532;
  margin-bottom: 15px;
}


@media screen and (min-width: 600px) {
  .app {
    padding: 40px 60px;
  }
  .applicationSegment {
    max-width: 800px;
  }
  .groupingDiv {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
  }
  .groupedField {
    width: 50%;
    max-width: 350px;
  }
  .groupedField:nth-child(1) {
    margin-right: 8px;
  }
}
